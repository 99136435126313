




























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OrderBumpPayload } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';

@Component({
  components: {
    MButton: () => import('@/shared/components/MButton.vue'),
    CheckboxInputBox: () => import('@/shared/components/CheckboxInputBox.vue'),
    OrderBumpSearchBox: () => import('./OrderBumpSearchBox.vue'),
  },
})
export default class OrderBumpSelectModal extends Vue {
  availableOrderBumps: Array<OrderBumpPayload> = [];
  selectedOrderBumps: Array<OrderBumpPayload> = [];
  filteredOrderBumps: Array<OrderBumpPayload> = [];
  initialSelectedOrderBumps: Array<OrderBumpPayload> = [];

  @Prop() preSelectedOrderBumps: Array<OrderBumpPayload>;

  async mounted() {
    try {
      const coreProductId = this.$route.params.id;
      const response = await SparkEcommerceApiClient.products.all({
        exclude_product: coreProductId,
        only_common_offers: true,
        items: 999,
      });

      this.availableOrderBumps = this.buildAvailableOrderBumps(response);
    } catch {
      this.availableOrderBumps = [];
    }
  }

  get preSelectedIds() {
    if (!this.preSelectedOrderBumps) return [];

    return this.preSelectedOrderBumps.map(orderBump => orderBump.id);
  }

  disabledOrderBump(orderBump) {
    return this.selectedOrderBumps.length >= 5 && this.selectedOrderBumps.indexOf(orderBump) === -1;
  }

  buildAvailableOrderBumps(products) {
    const filteredProducts = products.filter(product => Object.keys(product.default_offer).length > 0);

    let originalOrderBumps = filteredProducts.map(product => ({
      id: product.default_offer.id,
      title: product.name,
      coverImageUrl: product.cover_url,
      basePrice: product.default_offer.price_cents,
      anchorPrice: product.default_offer.price_cents / 100,
      callToAction: '',
      description: '',
    }));

    let preSelectedOrderBumps: Array<OrderBumpPayload> = [];

    if (this.preSelectedOrderBumps) {
      originalOrderBumps = originalOrderBumps.filter(orderBump => !this.preSelectedIds.includes(orderBump.id));
      preSelectedOrderBumps = this.preSelectedOrderBumps;
    }

    return [...preSelectedOrderBumps, ...originalOrderBumps];
  }

  selectOrderBumps() {
    this.initialSelectedOrderBumps = this.selectedOrderBumps;
    this.$emit('select', this.selectedOrderBumps);
    this.closeModal();
  }

  closeModal() {
    this.selectedOrderBumps = this.initialSelectedOrderBumps;
    this.$bvModal.hide('order-bump-select-modal');
  }

  setFilteredOrderBumps(items) {
    this.filteredOrderBumps = items;
  }

  @Watch('preSelectedIds', { immediate: true })
  onPreSelectedIdsChange(preSelectedIds) {
    this.selectedOrderBumps = this.availableOrderBumps.filter(orderBump => preSelectedIds.includes(orderBump.id));

    this.initialSelectedOrderBumps = this.selectedOrderBumps;
  }

  @Watch('availableOrderBumps', { immediate: true })
  onAvailableOrderBumpsChange(availableOrderBumps) {
    if (this.preSelectedIds.length && availableOrderBumps.length) {
      this.selectedOrderBumps = availableOrderBumps.filter(orderBump => this.preSelectedIds.includes(orderBump.id));

      this.initialSelectedOrderBumps = this.selectedOrderBumps;
    }
  }
}
